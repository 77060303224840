// workaround for each of the campaign components containing their own styles
// but they should be consistent with the CDS styles being added in one place
// e.g. font-family only once at the top level
import "@cochlear-design-system/foundation/dist/index.css";

import React from "react";
import { Text, RichText, Image } from "@sitecore-jss/sitecore-jss-react";
import Link from "../../../core/Components/Link";
import { DefaultProps, PropTypes } from "../../../core/Utils/SitecoreFields";
import ScrollAnimation from "react-animate-on-scroll";

class HeroBanner extends React.Component {
  render() {
    const { fields } = this.props;
    const {
      isFullWidthImage,
      image,
      title,
      description,
      hasFade,
      link,
      secondaryLink,
      curveImage,
      getStarted,
      themeColor,
      mobilecurvestyle,
    } = fields;
    const mobileCurveStyles =
      mobilecurvestyle?.fields.Title.value === "version-2" ? "version-2" : "";

    const contentBlock = (
      <header
        className={`landing-banner hero-banner ${
          isFullWidthImage.value ? "full-width" : ""
        } ${!image.value.src ? "no-image" : ""}`}
      >
        <div
          className={`background ${
            themeColor ? themeColor.fields.Title.value : ""
          }`}
        />
        {image.value.src && isFullWidthImage.value ? (
          <div
            className={`image top-part backImage ${mobileCurveStyles}`}
            style={{
              backgroundImage: `url(${image.value.src})`,
            }}
          >
            <Image field={image} data-amid={image.id} className="mb-show" />
          </div>
        ) : null}
        {curveImage.value.src && isFullWidthImage.value ? (
          <div
            className={`image top-part curveImage ${
              themeColor
                ? `mobile-curve ${themeColor.fields.Title.value} ${
                    mobilecurvestyle ? mobilecurvestyle.fields.Title.value : ""
                  }`
                : ""
            }`}
            style={{
              backgroundImage: `url(${curveImage.value.src})`,
            }}
          />
        ) : null}

        <div className="hero-banner-container">
          <div className="l-padding">
            <div className="hero-banner-description">
              <div className="card">
                <Text field={title} tag="h1" />
                <RichText field={description} className="description" />
              </div>
            </div>
            <div className="cta-grouping">
              {link.value.href ? (
                <div>
                  <Link field={link} className="cta-button-ni-link" />
                  <div>
                    {secondaryLink.value.href ? (
                      <Link
                        field={secondaryLink}
                        className="cta-button-ni-link secondary"
                      />
                    ) : null}
                  </div>
                </div>
              ) : null}
              {getStarted.value.href ? (
                <div className="get-started-div">
                  <Link
                    field={getStarted}
                    className={`cta-button-ni-link ${
                      themeColor ? themeColor.fields.Title.value : ""
                    }`}
                  />
                  <div>
                    {secondaryLink.value.href ? (
                      <Link
                        field={secondaryLink}
                        className={`cta-button-ni-link secondary ${
                          themeColor ? themeColor.fields.Title.value : ""
                        }`}
                      />
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </header>
    );
    return hasFade && hasFade.value ? (
      <ScrollAnimation
        animateIn="toFadeInUp"
        animateOnce
        delay={100}
        duration={0.5}
      >
        {contentBlock}
      </ScrollAnimation>
    ) : (
      contentBlock
    );
  }
}

HeroBanner.propTypes = PropTypes.SitecoreItem.inject({
  isFullWidthImage: PropTypes.Checkbox,
  title: PropTypes.SingleLineText,
  description: PropTypes.RichText,
  image: PropTypes.Image,
  curveImage: PropTypes.Image,
  hasFade: PropTypes.Checkbox,
  link: PropTypes.link,
  secondaryLink: PropTypes.link,
});

HeroBanner.defaultProps = DefaultProps.SitecoreItem.inject({
  isFullWidthImage: DefaultProps.Checkbox,
  title: PropTypes.SingleLineText,
  description: DefaultProps.RichText,
  image: DefaultProps.Image,
  curveImage: DefaultProps.Image,
  hasFade: DefaultProps.Checkbox,
  link: DefaultProps.link,
  secondaryLink: DefaultProps.link,
});

export default HeroBanner;
